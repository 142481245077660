import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './AdvicesGrid.module.scss'
import Item from './Item'

class AdvicesGrid extends Component {
  render() {
    const { advices, locale } = this.props
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          {advices &&
            advices.edges.map(({ node: { slug, title, id, image, adviceCategory } }) => (
              <Item slug={slug} title={title} img={image} adviceCategory={adviceCategory} key={id} locale={locale} />
            ))}
        </div>
      </div>
    )
  }
}

AdvicesGrid.propTypes = {
  advices: PropTypes.array.isRequired,
}

export default AdvicesGrid
