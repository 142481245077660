import React, { Component } from 'react'
import Header from 'src/components/Header'
import AdviceHeader from 'src/components/AdviceHeader'
import AdvicesGrid from 'src/components/AdvicesGrid'
import PageHelmet from 'src/components/PageHelmet'
import Footer from 'src/components/Footer'
import { graphql } from 'gatsby'
import withLayout from 'src/components/withLayout'

class AdviceCategories extends Component {
  state = {
    advices: this.props.data.advices,
  }

  render() {
    const { advices } = this.state

    const {
      data: {
        advicesCategory: { edges: advicesCategory },
      },
      pageContext: { locale },
      changeLocale,
    } = this.props
    return (
      <>
        <PageHelmet title="Advices" defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <AdviceHeader locale={locale} categories={advicesCategory} />
        <AdvicesGrid advices={advices} locale={locale} />
        <Footer locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query AdviceCategories($locale: String!, $title: String) {
    advices: allDatoCmsAdvice(filter: { locale: { eq: $locale }, adviceCategory: { title: { eq: $title } } }) {
      edges {
        node {
          title
          slug
          id
          adviceCategory {
            title
            slug
          }
          image {
            url
            fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    advicesCategory: allDatoCmsAdviceCategory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'advicesCategories',
}

export default withLayout(customProps)(AdviceCategories)
