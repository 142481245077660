import React, { Component } from 'react'
import { Link } from 'gatsby'
import SocialShare from 'src/components/SocialShare'
import styles from './Item.module.scss'
import { isOnline, removeListeners, getLink, getBaseUrl } from 'src/utils'

class Item extends Component {
  state = { isOnline: true }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const { slug, title, img, adviceCategory, locale } = this.props
    const socialShareEl = (
      <SocialShare title={title} url={`${getBaseUrl()}${locale}/advice/${slug}`} isWhite={true} locale={locale} />
    )

    return (
      <div className={styles.root} style={{ backgroundImage: `url(${img.url})` }}>
        {/* <Link to={getLink(locale, `advice/${slug}`)} className={styles.adviceLink}>
        //   <Img fluid={img.fluid} className={styles.image} />
        // </Link> */}
        <div className={styles.advice}>
          <Link
            to={getLink(locale, `advice/${slug}`)}
            className={styles.titleLink}
            style={{ backgroundImage: `url(${img.url})` }}
          >
            <h2>{title}</h2>
          </Link>
          <div className={styles.tags}>
            <Link className={styles.categoryLink} to={getLink(locale, `advice-category/${adviceCategory.slug}`)}>
              {adviceCategory.title}
            </Link>
            {this.state.isOnline ? socialShareEl : <div className={styles.offlineMode}>{socialShareEl}</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default Item
