import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { getLink } from 'src/utils'
import styles from './AdviceHeader.module.scss'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: classNames(styles.logo, styles.active) } : null
}

const PartialNavLink = (props) => <Link getProps={isPartiallyActive} {...props} />

class AdviceHeader extends Component {
  render() {
    const { categories, locale } = this.props
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <div className={styles.categories}>
            <PartialNavLink to={getLink(locale, 'advice-categories')} className={styles.link}>
              <FormattedMessage id="home.all" />
            </PartialNavLink>
            {categories.map(({ node: { id, slug, title } }) => (
              <PartialNavLink key={id} to={getLink(locale, `advice-category/${slug}`)} className={styles.link}>
                {title}
              </PartialNavLink>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

AdviceHeader.propTypes = {
  categories: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
}

export default AdviceHeader
